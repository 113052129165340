
import { createColumn, formatAsDate, formatAsCurrency } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';
import { OrderStatus } from '@/repositories/OrderRepository';
import RequestOrder from '@/models/RequestOrder';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      data: [],
      loading: false,
      appends: [
        createColumn('reviewDate', 'Reviewed Date', formatAsDate),
        createColumn('finalizedDate', 'Finalized Date', formatAsDate),
        createColumn(
          'grandTotal',
          'Request Grand Total',
          formatAsCurrency(row => row.currency.code)
        ),
        createColumn('paymentMethod', 'Payment Method')
      ],
      showSetDialog: false,
      showResultDialog: false,
      selectedOrder: new RequestOrder()
    };
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      this.loading = true;
      try {
        const { tenant } = await this.$repo.session.fetch();
        const { data, pagination } = await this.$repo.order.getOrderWithStatus(
          tenant,
          OrderStatus.WAITING_FOR_SCHEDULE,
          this.pagination
        );
        this.data = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchOrders();
    },
    showSetScheduleDialog(order: RequestOrder) {
      this.selectedOrder = order;
      this.showSetDialog = true;
    },
    async submitSchedule(schedule) {
      try {
        await this.$repo.order.scheduleInstallation(
          this.selectedOrder,
          schedule
        );
        handleSuccessUI(
          `Schedule has been successfully set to ${schedule.date} at ${schedule.time}`
        );
        this.data = this.data.filter(
          order => order.id !== this.selectedOrder.id
        );
      } catch (err) {
        handleErrorUI(err);
      }
      this.showSetDialog = false;
    }
  }
};
